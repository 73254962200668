const CUSTOM_API_URL = `https://www.thirdway.co.za/admin/wp-json/custom/v1`;
const CONTENT_API_URL = `https://www.thirdway.co.za/admin/wp-json/wp/v2`;

export const CONTENT_ROUTES = {
  SERVICES: function (params) {
    return `${CONTENT_API_URL}/service/${params}`;
  },
  TEAM: function (params) {
    return `${CONTENT_API_URL}/team-member/${params}`;
  },
  BANNERS: function (params) {
    return `${CONTENT_API_URL}/banner/${params}`;
  },
  PROJECTS: function (params) {
    return `${CONTENT_API_URL}/project/${params}`;
  },
  FUNDS: function (params) {
    return `${CONTENT_API_URL}/fund/${params}`;
  },
  MEDIA_BY_PAGE: function (id) {
    return `${CONTENT_API_URL}/media/?parent=${id}`;
  },
  MEDIA_BY_ID: function (id) {
    return `${CONTENT_API_URL}/media/${id}`;
  },
  POST_PAGE: function (type, slug) {
    return `${CONTENT_API_URL}${type}?slug=${slug}`;
  },
  POST_PAGE_BY_PARENT: function (type, parent_id) {
    return `${CONTENT_API_URL}${type}?parent=${parent_id}`;
  },
  SUBMIT_CONTACT_EMAIL: function () {
    return `${CUSTOM_API_URL}/contact-form-submission`;
  },
  SUBMIT_NEWSLETTER: function () {
    return `${CUSTOM_API_URL}/newsletter-form-submission`;
  },
};
