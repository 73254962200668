import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import FormContact from "../components/form-contact";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function Contact() {
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "contact-us"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);
        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            console.log(res.data);
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - Third Way`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main className="bg-[#270a51]">
        <section className="pt-16 pb-8">
          <div className="container mx-auto">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div>
                <div className="text-base leading-7 text-white md:px-0 px-8">
                  <h1 className="pb-5 text-4xl font-medium">
                    {page?.title?.rendered}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-8 text-white">
          <div className="container mx-auto md:px-0 px-8">
            <div className="md:flex">
              <article className="w-full md:w-2/4 md:mb-0 mb-8">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page?.content?.rendered,
                  }}
                />
              </article>
              <div className="w-full md:w-2/4">
                <h3>Email us now</h3>
                <GoogleReCaptchaProvider reCaptchaKey="6Ld6HGkqAAAAAHcUSoMHZIAhqUd_bXTaR2hltpVR">
                  <FormContact />
                </GoogleReCaptchaProvider>
              </div>
            </div>
          </div>
        </section>
        <section
          className="pb-[800px] bg-no-repeat bg-center bg-cover google_map"
          style={{
            backgroundImage: `url(${
              pageMedia?.find((media) => media?.id === page?.featured_media)
                ?.source_url
            })`,
          }}
        >
          <div className="relative container mx-auto md:px-0 px-8">
            <div
              className="md:w-1/2 w-full absolute top-0 right-0"
              dangerouslySetInnerHTML={{
                __html: page?.acf?.google_map,
              }}
            />
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
