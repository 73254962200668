import { useState, useEffect, useContext } from "react";
import { useFormValidation } from "../hooks/form-validation";
import { useForms } from "../hooks/forms";
import Alert from "./alert";
import { LoaderContext } from "../contexts/loader";
import { AppLoader } from "../components/app-loader";

export default function FormNewsletter({ bgType }) {
  let { validFormDetails } = useFormValidation();
  let { submitNewsletterForm } = useForms();
  const [invalidFormDetails, setInvalidFormDetails] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [formError, setFormError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  let { setLoading } = useContext(LoaderContext);

  const formFields = [
    { name: "first_name", display: "your first name" },
    { name: "last_name", display: "your last name" },
    { name: "organisation", display: "your organisation" },
    { name: "email", display: "your email address" },
  ];

  useEffect(() => {
    /* Defaults */
    setFormDetails((prevState) => ({
      ...prevState,
      [`first_name`]: "",
    }));
    setFormDetails((prevState) => ({
      ...prevState,
      [`last_name`]: "",
    }));
    setFormDetails((prevState) => ({
      ...prevState,
      [`email`]: "",
    }));
    setFormDetails((prevState) => ({
      ...prevState,
      [`organisation`]: "",
    }));
    setFormDetails((prevState) => ({
      ...prevState,
      [`newsletter`]: true,
    }));

    return () => {
      setInvalidFormDetails({});
      setFormDetails({});
      setFormError("");
      setFormSubmitted(false);
    };
  }, []);

  return (
    <>
      {!formSubmitted ? (
        <AppLoader
          loaderContent={
            <form
              onSubmit={(event) => {
                event.preventDefault();
                submitNewsletterForm(
                  formFields,
                  formDetails,
                  setInvalidFormDetails,
                  setFormError,
                  setFormSubmitted
                );
              }}
              method="POST"
            >
              <div className="mb-4">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  autoComplete="first_name"
                  className={`w-full flex-auto border-0 px-3.5 py-2 shadow-sm ring-0 focus:ring-0 placeholder-neutral-400 ${
                    bgType === "dark"
                      ? `${
                          invalidFormDetails.first_name
                            ? `bg-red-50`
                            : `bg-zinc-300/5 text-white`
                        }`
                      : `${
                          invalidFormDetails.first_name
                            ? `bg-red-50`
                            : `bg-zinc-300 text-zinc-500`
                        }`
                  }`}
                  onFocus={() => {
                    validFormDetails(
                      formFields,
                      formDetails,
                      setInvalidFormDetails,
                      setFormError
                    );
                  }}
                  onChange={(event) => {
                    setFormDetails((prevState) => ({
                      ...prevState,
                      [`first_name`]: event.target.value,
                    }));
                  }}
                  value={formDetails.first_name ? formDetails.first_name : ""}
                  placeholder="First name"
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  autoComplete="last_name"
                  className={`w-full flex-auto border-0 px-3.5 py-2 shadow-sm ring-0 focus:ring-0 placeholder-neutral-400 ${
                    bgType === "dark"
                      ? `${
                          invalidFormDetails.last_name
                            ? `bg-red-50`
                            : `bg-zinc-300/5 text-white`
                        }`
                      : `${
                          invalidFormDetails.last_name
                            ? `bg-red-50`
                            : `bg-zinc-300 text-zinc-500`
                        }`
                  }`}
                  onFocus={() => {
                    validFormDetails(
                      formFields,
                      formDetails,
                      setInvalidFormDetails,
                      setFormError
                    );
                  }}
                  onChange={(event) => {
                    setFormDetails((prevState) => ({
                      ...prevState,
                      [`last_name`]: event.target.value,
                    }));
                  }}
                  value={formDetails.last_name ? formDetails.last_name : ""}
                  placeholder="Last name"
                />
              </div>
              <div className="mb-4">
                <input
                  type="organisation"
                  name="organisation"
                  id="organisation"
                  autoComplete="organisation"
                  className={`w-full flex-auto border-0 px-3.5 py-2 shadow-sm ring-0 focus:ring-0 placeholder-neutral-400 ${
                    bgType === "dark"
                      ? `${
                          invalidFormDetails.organisation
                            ? `bg-red-50`
                            : `bg-zinc-300/5 text-white`
                        }`
                      : `${
                          invalidFormDetails.organisation
                            ? `bg-red-50`
                            : `bg-zinc-300 text-zinc-500`
                        }`
                  }`}
                  onFocus={() => {
                    validFormDetails(
                      formFields,
                      formDetails,
                      setInvalidFormDetails,
                      setFormError
                    );
                  }}
                  onChange={(event) => {
                    setFormDetails((prevState) => ({
                      ...prevState,
                      [`organisation`]: event.target.value,
                    }));
                  }}
                  value={
                    formDetails.organisation ? formDetails.organisation : ""
                  }
                  placeholder="Organisation"
                />
              </div>

              <div className="flex gap-x-4">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className={`w-full flex-auto border-0 px-3.5 py-2 shadow-sm ring-0 focus:ring-0 placeholder-neutral-400 ${
                    bgType === "dark"
                      ? `${
                          invalidFormDetails.email
                            ? `bg-red-50`
                            : `bg-zinc-300/5 text-white`
                        }`
                      : `${
                          invalidFormDetails.email
                            ? `bg-red-50`
                            : `bg-zinc-300 text-zinc-500`
                        }`
                  }`}
                  onFocus={() => {
                    validFormDetails(
                      formFields,
                      formDetails,
                      setInvalidFormDetails,
                      setFormError
                    );
                  }}
                  onChange={(event) => {
                    setFormDetails((prevState) => ({
                      ...prevState,
                      [`email`]: event.target.value,
                    }));
                  }}
                  value={formDetails.email ? formDetails.email : ""}
                  placeholder="Email address"
                />
                <button
                  type="submit"
                  className="flex-none bg-[#e86861] px-5 py-2 text-white inline-block"
                >
                  Sign-up
                </button>
              </div>
              <div>
                {formError.length !== 0 && (
                  <div className="text-red-500 mt-4">{formError}</div>
                )}
              </div>
            </form>
          }
        />
      ) : (
        <>
          <Alert
            type={`success`}
            message={`<b>Successfully submitted.</b> Thank you for subscribing to our mailing lists.`}
          />
        </>
      )}
    </>
  );
}
